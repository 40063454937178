//
// APPS
// - - - - - - - - - - - - - -

#block-apps {
  #apps {
    .app-wrapper {
      margin-bottom: 20px;

      #app {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        padding: 40px 60px;
        background-color: $white;
        justify-content: center;
        text-align: center;
        height: 100%;

        a.url {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        .icon {
          text-align: center;
          float: left;
          color: $primary;
        }

        .content {
          margin: 20px 0;
          flex: 1 0 100%;
        }

        &:hover {
          box-shadow: 0px 2px 5.94px 0.06px rgba(0, 0, 0, 0.2);
          transition: all 0.3s;

          .cta-btn {
            background-position: 100% 0;
            transition: all 0.3s ease-in-out;
            color: $white;
            text-decoration: none;
          }
        }
      }
    }
  }
}
