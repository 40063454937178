//
// CONTACTS
// - - - - - - - - - - - - - - -

.inquiry-header {
  padding-top: 50px;
  padding-bottom: 50px;
  background: $blue-background-light;

  @include media-breakpoint-up(md) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  h1 {
    font-size: 36px;
    color: $white;
    font-family: $font-primary-bold;

    @include media-breakpoint-up(md) {
      font-size: 50px;
    }
  }
}

.form {
  padding-top: 70px;
  padding-bottom: 50px;

  .contact-form {

    input, textarea{
      border-style: solid;
      border-color: $grey;
      border-radius: 5px;
    }

    .form-group-title{
      color: $grey-dark;
      font-size: 20px;
      font-family: $font-primary-bold;
      margin-bottom: 20px;
    }
    .star {
      color: red;
      font-family: $font-primary-bold;
    }

    #message {
      padding: 8px;
      min-height: 180px;
    }

    .cta-btn {
      border: none;
      cursor: pointer;
    }
  }
}

