#zaposlitev {
  padding-bottom: 60px;

  .top-content {
    padding-top: 50px;

    h1 {
      font-size: 36px;
      line-height: 45px;
      font-family: "Fira Sans", Arial, sans-serif;
      font-family: $font-primary-bold;
      margin: 0 0 20px;
      color: #404041;
      text-align: center;
    }
  }

  .zaposlitve {
    .zaposlitev-element {
      background: $white;
      padding: 40px;
      margin-top: 30px;
      margin-bottom: 30px;

      .zaposlitev-preview {
        h2 {
          color: $secondary;
        }
      }

      &:nth-child(even) {
        background: $black-two;

        .zaposlitev-preview {
          h2,
          p,
          a {
            color: $white;
          }
        }
      }

      @include media-breakpoint-up(md) {
        padding: 60px;
      }

      .zaposlitev-image {
        img {
          margin-top: 30px;
          border-radius: 50%;

          @include media-breakpoint-up(lg) {
            margin-top: 0;
          }
        }
      }
    }
  }
}

#single-zaposlitev {
  margin-top: 80px;

  @media only screen and (min-width: 340px) {
    margin-top: 160px;
  }

  .category {
    font-style: italic;
    font-size: 16px;
    color: $grey-dark;
    font-family: "Fira Sans", Arial, sans-serif;
    text-transform: uppercase;
    margin: 0 0 30px;
  }

  h1 {
    font-size: 36px;
    line-height: 45px;
    font-family: $font-primary-bold;
    color: $grey-dark;

    &:after {
      @extend .heading-underline;
    }
  }

  h3 {
    color: $grey-dark;
    margin-bottom: 0;
  }
}

#zaposlitev-subpage {
  margin-top: 96px;

  h2 {
    color: $white;
  }

  .zaposlitev-back {
    font-size: 14px;
    color: $white;
    opacity: 0.9;

    &:hover {
      text-decoration: none;
    }

    span {
      &:before {
        width: 5px;
        height: 10px;
      }
    }
  }
}

.zaposlitev-img {
  border-radius: 50%;
  position: relative;
  top: 100px;
}
