//
// CONTACT
// - - - - - - - - - - - - - - - -

#contact-page {
  margin-top: 80px;
  margin-bottom: 40px;

  h1 {
    font-size: 36px;
    font-family: $font-primary-bold;
    color: $grey-dark;

    &:after {
      @extend .heading-underline;
    }
  }
}

#map-placeholder {
  height: 400px;
  cursor: pointer;
  position: relative;
  transition: opacity ease-in-out;
  background: url(/img/map-placeholder.jpg) no-repeat center center / cover;

  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.45);
    z-index: 100;
  }

  .map-cursor-icon {
    display: block;
    width: 52px;
    height: 77px;
    margin: 0 auto 30px;
    background: url("data:image/svg+xml;charset=UTF-8, %3csvg width='52' height='77' viewBox='0 0 52 77' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M40.6191 72.7342L29.8341 74.9203C29.6004 74.9677 29.3632 74.8567 29.2499 74.6469C25.9956 68.6204 21.1163 63.6275 15.1663 60.2354L4.96754 54.4212C4.34815 54.0681 4.19685 53.2418 4.65093 52.6921L6.58935 50.3456C9.196 47.1902 13.6522 46.2835 17.2846 48.1696L20.372 49.7727C20.6651 49.9248 21.0152 49.7122 21.0152 49.3819V30.0003C21.0152 27.0392 23.4157 24.6387 26.3769 24.6387C29.338 24.6387 31.7385 27.0392 31.7385 30.0003V40.869C31.7385 41.0071 31.8363 41.1258 31.9718 41.1524L42.7571 43.2672C47.3432 44.1664 50.1853 48.7885 48.9181 53.2868L44.4385 69.1896C43.9303 70.9936 42.4559 72.3619 40.6191 72.7342Z' stroke='white' stroke-width='4'/%3e%3cpath d='M6.92585 30.0001C6.94613 25.1262 8.81559 20.2586 12.5342 16.54C20.0123 9.06189 32.1367 9.06189 39.6148 16.54C43.3334 20.2586 45.2029 25.1262 45.2232 30.0001' stroke='white' stroke-width='4' stroke-linecap='round'/%3e%3c/svg%3e")
      no-repeat center center / contain;
  }

  p {
    font-size: 22px;
    color: white;
    position: absolute;
    margin: 0;
    top: 50%;
    left: 0%;
    width: 100%;
    text-align: center;
    -ms-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    cursor: pointer;
    z-index: 200;
  }
}

.info-window-content {
  p {
    font-size: 13px;
  }

  a {
    color: $black;
    text-decoration: underline;

    &:hover {
      color: $primary;
    }
  }
}

.gm-style-iw div * {
  overflow: hidden !important;
  line-height: 1.35em;
}
