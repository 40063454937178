.header-container {
  background: $white;
  position: fixed;
  z-index: 9999;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 0 52px rgba(21, 49, 62, 0.2);

  .top-header {
    .email {
      a {
        span {
          display: none;

          @include media-breakpoint-up(md) {
            display: inline-block;
          }
        }

        &:before {
          @extend .icon-mail;
          width: 23px;
          height: 23px;

          @include media-breakpoint-up(md) {
            width: 16px;
            height: 16px;
            margin-right: 6px;
            top: 3px;
          }
        }
      }
    }

    .phone {
      a {
        span {
          display: none;

          @include media-breakpoint-up(md) {
            display: inline-block;
          }
        }

        &:before {
          @extend .icon-phone;
          width: 23px;
          height: 23px;

          @include media-breakpoint-up(md) {
            width: 16px;
            height: 16px;
            margin-right: 6px;
            top: 3px;
          }
        }
      }
    }

    .facebook {
      a {
        &:before {
          @extend .icon-facebook;
          width: 23px;
          height: 26px;
        }
      }
    }

    .instagram {
      a {
        &:before {
          @extend .icon-instagram;
          width: 23px;
          height: 26px;
        }
      }
    }

    a {
      color: $grey-dark;
      font-size: 16px;
      font-family: $font-primary;
      text-decoration: none;
    }
  }

  .main-header {
    .navbar {
      padding: 0;

      .logo {
        img {
          width: 100px;

          @include media-breakpoint-up(xl) {
            width: auto;
          }
        }
        h1 {
          margin-top: 0;
          margin-bottom: 0;
          font-size: 0px;
          line-height: 6px;
        }
        &.scroll {
          img {
            width: 100px;
          }
        }
      }

      #navigation {
        justify-content: flex-end;
        background: $white;

        .navbar-collapse {
          .navi {
            text-align: center;

            @include media-breakpoint-up(lg) {
              display: flex;
            }

            .cta-btn {
              @extend .cta-btn;
              padding: 12px 26px;

              @include media-breakpoint-up(lg) {
                margin-left: 24px;
              }
            }

            @include media-breakpoint-up(lg) {
              align-items: center;
            }

            ul {
              margin: 0;
              padding: 0;

              &.level2 {
                @include media-breakpoint-up(lg) {
                  padding-left: 0;
                  display: none;
                  position: absolute;
                  top: 55px;
                  left: -20px;
                  width: 240px;
                  background: $white;
                  z-index: 500;
                  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
                  border: 1px solid $grey-light;

                  &:before {
                    content: "";
                    height: 5px;
                    width: 240px;
                    display: block;
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    background: $primary;
                  }
                }
              }

              &.level3 {
                @include media-breakpoint-up(lg) {
                  padding-left: 0;
                  display: none;
                  position: absolute;
                  top: -10px;
                  left: 200px;
                  width: 240px;
                  background: $white;
                  z-index: 500;
                  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
                  border: 1px solid $grey-light;

                  &:before {
                    content: "";
                    height: 5px;
                    width: 240px;
                    display: block;
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    background: $primary;
                  }
                }
              }

              li {
                list-style: none;
                display: block;
                position: relative;

                a {
                  font-family: $font-primary;
                  color: $grey-dark;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 24px;
                  transition: all 0.3s ease-in-out;
                  display: block;
                  text-align: center;
                  text-decoration: none;

                  @include media-breakpoint-up(lg) {
                    font-size: 12px;
                    text-align: left;
                  }

                  @include media-breakpoint-up(xl) {
                    font-size: 14px;
                  }

                  &.level1 {
                    font-family: $font-primary-bold;
                    text-transform: uppercase;
                    padding: 10px 0;

                    @include media-breakpoint-up(lg) {
                      padding: 20px 0;
                    }

                    &:hover,
                    &.active {
                      color: $primary;
                    }
                  }

                  &.level2,
                  &.level3 {
                    padding: 10px 0;

                    @include media-breakpoint-up(lg) {
                      border-bottom: 1px dashed $grey-light;
                    }

                    &:hover,
                    &.active {
                      color: $primary;
                    }
                  }
                }

                &.level1 {
                  @include media-breakpoint-up(lg) {
                    display: inline-block;
                    margin: 0 18px;

                    &:after {
                      content: "/";
                      font-family: $font-primary;
                      font-weight: 400;
                      color: $grey-light;
                      font-style: italic;
                      font-size: 14px;
                      position: absolute;
                      top: 22px;
                      right: -24px;
                    }
                  }

                  &:hover ul.level2 {
                    display: block;
                  }

                  &.sublevels {
                    a.level1 {
                      &:after {
                        @include media-breakpoint-up(lg) {
                          @include icon-arrow;
                          transform: rotate(90deg);
                          transition: all 0.2s linear;
                          top: -1px;
                          width: 6px;
                          height: 8px;
                          margin-left: 6px;
                        }
                      }

                      &:hover {
                        &:after {
                          transform: rotate(-90deg);
                        }
                      }
                    }
                  }
                }

                &.level2 {
                  &:first-child {
                    @include media-breakpoint-up(lg) {
                      padding-top: 10px;
                    }
                  }

                  &:last-child {
                    @include media-breakpoint-up(lg) {
                      padding-bottom: 10px;
                    }
                  }

                  &:hover ul.level3 {
                    display: block;
                  }

                  @include media-breakpoint-up(lg) {
                    padding: 0 15px;
                  }
                }

                &.level3 {
                  &:first-child {
                    @include media-breakpoint-up(lg) {
                      padding-top: 10px;
                    }
                  }

                  &:last-child {
                    @include media-breakpoint-up(lg) {
                      padding-bottom: 10px;
                    }
                  }

                  @include media-breakpoint-up(lg) {
                    padding: 0 15px;
                  }
                }
              }

              a.collapse {
                display: none;
              }

              a.collapse,
              a.expand {
                padding: 6px 14px;

                @include media-breakpoint-up(lg) {
                  display: none !important;
                }

                position: absolute;
                top: 0;
                right: 0;

                &.icon-expand {
                  &:before {
                    @include icon-arrow;

                    width: 10px;
                    height: 18px;
                    transform: rotate(90deg);
                    top: 5px;
                  }
                }

                &.icon-collapse {
                  &:before {
                    @include icon-arrow;

                    width: 10px;
                    height: 18px;
                    transform: rotate(-90deg);
                    top: 3px;
                  }
                }

                &:hover {
                  background: $primary;
                  color: $white;

                  .icon-expand,
                  .icon-collapse {
                    border-color: $white;
                  }
                }
              }

              li.level2,
              li.level3 {
                &.active {
                  a.expand {
                    .icon-expand {
                      border-color: $blue;
                    }

                    &:hover {
                      background: $primary;
                      color: $white;

                      .icon-expand {
                        border-color: $white;
                      }
                    }
                  }
                  a.collapse {
                    .icon-collapse {
                      border-color: $blue;
                    }

                    &:hover {
                      background: $primary;
                      color: $white;

                      .icon-collapse {
                        border-color: $white;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .navbar-button {
        .menu-btn {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 35px;
          height: 40px;
          cursor: pointer;
          transition: all 0.5s;
          z-index: 2;

          .menu-btn-burger {
            z-index: 0;
            width: 35px;
            height: 3px;
            position: absolute;
            background: $primary;
            transition: all 0.4s;

            &:before,
            &:after {
              content: "";
              position: absolute;
              z-index: 0;
              width: 35px;
              height: 3px;
              background: $primary;
              transition: all 0.5s ease-in-out;
            }

            &:before {
              transform: translateY(-9px);
            }

            &:after {
              transform: translateY(9px);
            }
          }

          &.is-active {
            .menu-btn-burger {
              transform: translateX(-50px);
              background: transparent;
              box-shadow: none;

              &:before {
                transform: rotate(45deg) translate(35px, -35px);
              }

              &:after {
                transform: rotate(-45deg) translate(35px, 35px);
              }
            }
          }
        }

        .hamburger {
          font: inherit;
          display: inline-block;
          overflow: visible;
          cursor: pointer;
          transition-timing-function: linear;
          transition-duration: 0.15s;
          transition-property: opacity, filter;
          border: 0;
          background-color: transparent;

          .hamburger-box {
            position: relative;
            display: inline-block;
            width: 40px;
            height: 24px;

            .hamburger-inner {
              position: absolute;
              width: 40px;
              height: 4px;
              transition-timing-function: ease;
              transition-duration: 0.15s;
              transition-property: transform;
              border-radius: 0;

              top: 50%;
              display: block;
              margin-top: -2px;
              top: 2px;

              &:after,
              &:before {
                display: block;
                content: "";
                position: absolute;
                width: 40px;
                height: 4px;
                transition-timing-function: ease;
                transition-duration: 0.15s;
                transition-property: transform;
                border-radius: 0;

                top: 50%;
                display: block;
              }

              &:before {
                top: 10px;
                transition-timing-function: ease;
                transition-duration: 0.15s;
                transition-property: transform, opacity;
              }

              &:after {
                top: 20px;
              }
            }
          }

          &.is-active {
            .hamburger-inner {
              transform: translate3d(0, 10px, 0) rotate(45deg);

              &:before {
                transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
                opacity: 0;
              }

              &:after {
                transform: translate3d(0, -20px, 0) rotate(-90deg);
              }
            }
          }
        }
      }
    }
  }
}
