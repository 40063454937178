form {
    textarea {
      vertical-align: top;
    }
  
    .form-group {
      margin-bottom: 14px;
      position: relative;
  
      @include media-breakpoint-up(lg) {
        margin-bottom: 24px;
      }
  
      &.comment {
        padding-left: 0;
        padding-right: 0;
      }
  
      &.text-group {
        margin-bottom: 12px;
        .text {
          font-size: 13px;
          line-height: 18px;
          padding: 8px;
          background: #f2f7fa;
          position: relative;
          top: -10px;
  
          @include media-breakpoint-up(md) {
            font-size: 14px;
            line-height: 19px;
          }
        }
      }
  
      .form-control {
        width: 100%;
        height: 40px;
        margin-top: 8px;
        margin-bottom: 0;
        padding: 0 8px;
        color: $black;
        border-radius: 0;
        font-family: $font-primary;
        background: $white;
        border-width: 1px;
  
        @include media-breakpoint-up(lg) {
          height: 48px;
        }
  
        &:disabled,
        &[readonly] {
          background: #ececec;
        }
  
        &:disabled,
        &[readonly] {
          background: #fff;
          color: $black;
        }
  
        &#country_id {
          appearance: none;
          text-indent: 1px;
          cursor: pointer;
  
          &:after {
            content: "";
          }
        }
  
        &.comment {
          height: auto;
          padding: 15px;
          font-size: 15px;
          font-family: $font-primary;
        }
  
        &.textarea {
          height: auto;
          padding-top: 15px;
        }
      }
    }
  
    .form-group-half {
      .first {
        margin-bottom: 14px;
  
        @include media-breakpoint-up(sm) {
          margin-bottom: 0;
        }
      }
    }
  
    .invalid-tooltip {
      position: relative;
      z-index: 5;
      display: none;
      max-width: 100%;
      padding: 0.25rem 0.5rem;
      margin-top: 0.1rem;
      font-size: 0.875rem;
      line-height: 1.5;
      color: $white;
      background-color: $red;
      border-radius: 4px;
    }
  
    .control-label {
      display: block;
      position: absolute;
      opacity: 0;
      padding: 0 5px;
      top: 12px;
      left: 12px;
      color: $secondary;
      margin-bottom: 0;
      transition: 0.2s ease-in-out transform;
      font-size: 12px;
  
      &[for="zip"],
      &[for="city"] {
        left: 28px;
      }
    }
  
    .form-control:placeholder-shown + .control-label {
      visibility: hidden;
      z-index: -1;
      transition: 0.2s ease-in-out;
    }
    .form-control:not(:placeholder-shown) + .control-label,
    .form-control:focus:not(:placeholder-shown) + .control-label {
      visibility: visible;
      z-index: 1;
      opacity: 1;
      background: $white;
      transform: translateY(-10px);
      transition: transform 0.2s ease-in-out, background 0.25s ease-in;
    }
  
    &.was-validated {
      .form-group {
        input {
          &:invalid {
            border-color: $red;
            background: $white;
          }
        }
  
        .form-control {
          &:valid,
          &.is-valid {
            border-color: $black;
            background: $white;
            &:focus {
              border-color: $black;
            }
          }
        }
      }
  
      .form-check-input:valid ~ .form-check-label,
      .form-check-input.is-valid ~ .form-check-label {
        color: $secondary;
      }
  
      .valid-feedback,
      .invalid-feedback {
        position: absolute;
        left: 16px;
      }
  
      .invalid-tooltip {
        margin-top: 6px;
        padding-top: 2px;
        padding-bottom: 2px;
      }
  
      :invalid ~ .invalid-feedback,
      :invalid ~ .invalid-tooltip,
      ~ .invalid-feedback,
      ~ .invalid-tooltip {
        display: block;
      }
    }
  
    .btn-submit {
      display: block;
      margin: 40px auto 0;
    }
  
    .form-group-switch {
      display: flex;
      margin-top: 8px;
      margin-bottom: 27px;
  
      label.switch {
        position: relative;
        display: flex;
        margin-bottom: 0;
  
        a {
          text-decoration: underline;
        }
  
        input {

          appearance: none;
          display: none;
  
          + .slider {
            + .yesno {
              span {
                display: inline-block;
                width: 26px;
              }
              .no {
                display: block;
              }
  
              .yes {
                display: none;
              }
            }
          }
  
          &:checked + .slider {
            background-color: $green;
  
            + .yesno {
              .no {
                display: none;
              }
  
              .yes {
                display: block;
              }
            }
          }
  
          &:focus {
            outline: none;
            border: none;
            + .slider {
              box-shadow: 0 0 1px #2196f3;
              border: none;
              outline: 2px solid #6495ed;
              outline-offset: 2px;
            }
          }
  
          &:checked + .slider:before {
            transform: translateX(20px);
          }
        }
  
        .yesno {
          display: block;
          margin-left: 45px;
          font-family: $font-primary-bold;
          font-style: normal;
          font-size: 15px;
          line-height: 21px;
          color: $secondary;
        }
  
        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: $black;
      
          transition: 0.4s;
          border-radius: 34px;
          width: 40px;
          height: 20px;
  
          &:before {
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            left: 2px;
            bottom: 2px;
            background-color: white;
           
            transition: 0.4s;
            border-radius: 50%;
          }
        }
      }
  
      .text {
        margin-left: 12px;
        cursor: pointer;
        font-size: 12px;
  
        @include media-breakpoint-up(lg) {
          line-height: 22px;
          font-size: 16px;
        }
      }
  
      .newsletter-subscribe {
        margin-top: 4px;
        margin-left: 40px;
        margin-bottom: 40px;
        font-family: $font-primary;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: $secondary;
      }
    }
  
    .form-errors {
      display: none;
    }
    &.has-errors {
      .form-errors {
        display: block;
        margin-top: 10px;
        color: $red;
      }
    }
  }
  
  .radio-container {
    .form-check {
      padding: 0;
      label {
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin: 0 0 20px;
        padding: 0 0 0 5px;
        color: $black;
  
        .radio_input {
          display: grid;
          grid-template-columns: -webkit-min-content auto;
          grid-template-columns: min-content auto;
          grid-gap: 0.5em;
          font-size: 2.25rem;
          flex: 1;
  
          .radio_control {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 23px;
            height: 23px;
            border-radius: 50%;
            border: 2px solid $black;
  
            &:before {
              content: "";
              width: 11px;
              height: 11px;
              box-shadow: inset 0.5em 0.5em $black;
              border-radius: 50%;
              transition: 180ms transform ease-in-out;
              transform: scale(0);
            }
          }
  
          .radio_label {
            font-size: 20px;
            font-family: $font-primary;
            font-weight: 400;
            line-height: 27px;
          }
        }
  
        .type-content {
          width: 100%;
          padding: 0 42px;
          height: 0;
          opacity: 0;
        }
  
        input {
          opacity: 0;
          width: 0;
          height: 0;
          margin: 0;
          position: relative;
  
          &:focus + .radio_control {
            box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em $black;
          }
  
          &:checked + .radio_input {
            .radio_control {
              box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em $black;
  
              &:before {
                transform: scale(1);
              }
            }
  
            .radio_label {
              font-family: $font-primary-bold;
            }
          }
  
          &:checked ~ .type-content {
            padding: 20px 42px 10px;
            height: auto;
            opacity: 1;
          }
        }
      }
    }
  }
  