footer {
  #contact-info {
    padding: 40px;
    text-align: center;
    background-color: $grey-background-two;

    .inner-container {
      justify-content: center;
    }

    .contact {
      border-right: 1px solid $grey-text;
      padding-right: 20px;
      margin-right: 20px;
      padding-top: 8px;
      padding-bottom: 8px;

      .email {
        &:before {
          @extend .icon-mail-lightgray;
          width: 16px;
          height: 16px;
          top: 3px;
          right: 6px;
        }
      }

      .phone {
        &:before {
          @extend .icon-phone-lightgray;
          width: 15px;
          height: 15px;
          top: 2px;
          right: 6px;
        }
      }

      a {
        margin-right: 22px;
        color: $grey-light;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        font-style: normal;
        font-family: $font-primary;
        display: block;
        padding-top: 8px;
        padding-bottom: 8px;
        text-decoration: none;

        @include media-breakpoint-up(sm) {
          display: inline;
        }

        &:hover {
          text-decoration: none;
        }

      }

      @include media-breakpoint-down(lg) {
        width: 100%;
        float: none;
        border: none;
        padding: 0;
        margin: 0;
        text-align: center;
      }
    }

    .apps {
      ul {
        margin: 0;
        padding: 0;

        li {
          list-style: none;
          display: inline-block;
          margin: 0 20px;

          &:before {
            display: none;
          }
        }
      }

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }

  #copy {
    background-color: $grey-background;
    padding-top: 20px;
    padding-bottom: 20px;

    p {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 20px;
      color: #b2b2b2;
      font-family: $font-primary;
    }

    a {
      font-size: 14px;
      line-height: 20px;
      color: #b2b2b2;
      font-family: $font-primary;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
