.solutions {
  .solution {
    margin-bottom: 20px;
    
    .solution-inner {
      position: relative;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      padding: 40px 60px 50px;
      background-color: $white;
      justify-content: center;
      text-align: center;
      height: 100%;

      a.url {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .icon {
        width: 90px;
        height: 90px;
        line-height: 90px;
        text-align: center;
        border-radius: 50%;
        background: $white;
        border: 1px dashed $grey-light;
        color: $primary;
      }

      .content {
        margin: 20px 0;
        flex: 1 0 100%;

        .title {
          h2 {
            font-size: 22px;
            margin-bottom: 10px;
          }
        }
      }
      &:hover {
        box-shadow: 0px 2px 5.94px 0.06px rgba(0, 0, 0, 0.2);
        transition: all 0.3s;

        .cta-btn {
          background-position: 100% 0;
          transition: all 0.3s ease-in-out;
          color: $white;
          text-decoration: none;
        }
      }
    }
  }
}
