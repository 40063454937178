.btn {
  font-family: $font-primary-bold;
  font-size: 15px;
  text-align: center;
  padding: 17px 30px;
  text-transform: uppercase;
  border-radius: 100px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  background-size: 300% 100%;
  display: inline-block;
  cursor: pointer;

  &:hover {
    background-position: 100% 0;
    transition: all 0.3s ease-in-out;
    color: $white;
    text-decoration: none;
  }
}

.cta-btn {
  @extend .btn;
  color: $white;
  background-image: linear-gradient(
    to right,
    #f07a31,
    #ff9901,
    #0066b3,
    #007ecc
  );
}

.cta-btn-white {
  @extend .btn;
  color: $secondary;
  background-image: linear-gradient(
    to right,
    #ffffff,
    #ffffff,
    #0066b3,
    #007ecc
  );
}
