//
// DEFAULT
// - - - - - - - - - - - - - - -

* {
  outline: 0;
  box-sizing: border-box;

  &:focus-visible {
    outline: 2px solid $primary;
    outline-offset: 2px;
  }

  &:focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
  }

  &::selection {
    background: $primary;
    color: #fff;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

body {
  margin: 0;
  font-family: $font-primary;

  h2,
  h3,
  h4 {
    margin-top: 0;
  }

  p {
    margin-top: 0;
  }
}

h2 {
  font-family: $font-primary-bold;
  font-size: 32px;
  line-height: 38px;
  color: $grey-dark;
  margin-bottom: 20px;
}

h3,
.main-cookie-title {
  font-style: normal;
  font-family: $font-primary-bold;
  font-size: 24px;
  line-height: 34px;
  color: $primary;

  a {
    color: $primary;
    text-decoration: underline;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}

h4 {
  font-size: 24px;
  color: $grey-dark;
  margin-bottom: 10px;
}

p {
  margin-bottom: 0;
  font-family: $font-primary;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: $grey-dark;
}

.text-white {
  color: $white;
}

.text-center {
  text-align: center;
}

.img-fluid {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

ul {
  padding-left: 15px;
}

table {
  tr {
    th,
    td {
      padding: 10px;
      border-top: 1px solid $grey-light;
      vertical-align: top;
    }
  }
  td {
    h4 {
      color: $primary;
    }

    p {
      color: $white;
    }
  }
}

#content-container {
  padding-top: 48px;
  padding-bottom: 90px;
}

#main {
  padding-top: 116px;

  @include media-breakpoint-up(xl) {
    padding-top: 134px;
  }
}

.two-col {
  h3 {
    margin-bottom: 10px;
  }

  column-count: auto;
  column-gap: 80px;

  @include media-breakpoint-up(lg) {
    column-count: 2;
  }
}

.two-col {
  h3 {
    margin-bottom: 10px;
  }

  column-count: auto;
  column-gap: 80px;

  @include media-breakpoint-up(lg) {
    column-count: 2;
  }
}

.content-edit,
.content-img-right,
.content-img-bottom {
  .cta-btn {
    color: $white;
    text-decoration: none;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
  a {
    color: $black;
    text-decoration: underline;

    &:hover {
      color: $primary;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      margin: 0;
      padding-left: 1.2em;
      padding-top: 8px;
      padding-bottom: 8px;
      text-indent: -1.5em;

      &:before {
        content: "";
        display: inline-block;
        background: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.5445 3.70547C16.984 4.14493 16.984 4.8586 16.5445 5.29805L7.54454 14.2981C7.10509 14.7375 6.39142 14.7375 5.95197 14.2981L1.45197 9.79805C1.01251 9.3586 1.01251 8.64493 1.45197 8.20547C1.89142 7.76602 2.60509 7.76602 3.04454 8.20547L6.75001 11.9074L14.9555 3.70547C15.3949 3.26602 16.1086 3.26602 16.5481 3.70547H16.5445Z' fill='%23F07A31'/%3E%3C/svg%3E%0A")
          no-repeat center center / cover;
        position: relative;
        width: 18px;
        height: 18px;
        top: 4px;
        margin-right: 7px;
      }
    }
  }
}

#top-content {
  background: $grey-background-two;

  h2 {
    color: $primary;
  }

  p {
    color: $white;
  }
}

#white-one {
  background: $white;

  &.grey-background-light {
    background: $grey-background-light;
  }

  .row {
    justify-content: center;
  }
}

#dark {
  background: $grey-background-two;

  h2 {
    color: $primary;
  }

  p {
    color: $white;
  }

  ul {
    li {
      color: $white;
    }
  }

  a {
    color: $primary;

    &:hover {
      text-decoration: none;
    }
  }
}

#white-two,
#white-three {
  background: $white;

  table {
    tbody {
      tr {
        td {
          p {
            color: $grey-dark;
          }
        }
      }
    }
  }
}

#dark-blue {
  background: $blue-background-dark;

  h2,
  p {
    color: $white;
  }

  ul {
    li {
      color: $white;
    }
  }

  .row {
    gap: 30px 0;
  }
}

#grey {
  background: $grey-background-light;
}

#dark-blue-two {
  background: $blue-background-dark;

  h2,
  p {
    color: $white;
  }

  ul {
    li {
      color: $white;
    }
  }
}

#parallax {
  position: relative;

  .parallax {
    padding-top: 80px;
    padding-bottom: 80px;

    @include media-breakpoint-up(md) {
      padding-top: 182px;
      padding-bottom: 182px;
    }

    &:before {
      content: "";
      background: rgba(0, 0, 0, 0.4);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      z-index: 10;
    }

    .inner-content {
      position: relative;
      z-index: 11;
      margin-left: 0;
      margin-right: 0;

      h2,
      h3 {
        color: $white;
      }
      p {
        color: $white;
        font-size: 18px;
        line-height: 24px;

        @include media-breakpoint-up(md) {
          font-size: 22px;
          line-height: 28px;
        }
      }
    }
  }
}

.parallax:before {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: block;
}

#contact-subpage,
#zaposlitev-subpage {
  text-align: center;
  background: $blue-background-light;

  p {
    color: $white;
    margin-bottom: 25px;
  }
}

.table-responsive {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        display: block;
        width: 100%;
        overflow-x: auto;

        > .table-bordered {
          border: 0;
        }
      }
    }
  }
}

.cta-btn-alter {
  @extend .cta-btn;
  padding: 14px 30px;

  background-image: linear-gradient(
    to right,
    #f07a31,
    #ff9901,
    #ffffff,
    #ffffff
  );

  &:hover {
    background-position: 100% 0;
    transition: all 0.3s ease-in-out;
    color: $primary !important;
    text-decoration: none;
  }
}

.error-page {
  position: relative;
  height: 60vh;

  @include media-breakpoint-up(lg) {
    height: 100%;
  }

  .error-content {
    z-index: 10;

    h1 {
      color: $white;
      font-size: 40px;
      line-height: 50px;
      text-transform: uppercase;
      margin: 0;
    }

    h2 {
      color: $white;
      font-size: 34px;
      line-height: 44px;
      margin: 0;

      // @media (min-width: 1400px) {
      //   margin-top: 30px
      // }
    }

    p {
      color: $white;
      margin-bottom: 32px;
    }

    &.content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      padding-left: 16px;
      padding-right: 16px;
      width: 100%;
    }
  }

  .background-image {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  canvas {
    position: relative;
    margin: 0 auto;
    display: block;
    z-index: 10;
  }

  #restart{
    position: absolute;
    right: 20px;
    bottom: 90px
  }
}
