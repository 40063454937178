@font-face {
  font-family: 'Fira Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/fira-sans-v10-latin-ext_latin-regular.eot');
  src: local('Fira Sans Regular'), local('FiraSans-Regular'),
  url('./fonts/fira-sans-v10-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'),
  url('./fonts/fira-sans-v10-latin-ext_latin-regular.woff2') format('woff2'),
  url('./fonts/fira-sans-v10-latin-ext_latin-regular.woff') format('woff'),
  url('./fonts/fira-sans-v10-latin-ext_latin-regular.ttf') format('truetype'),
  url('./fonts/fira-sans-v10-latin-ext_latin-regular.svg#FiraSans') format('svg');
}


@font-face {
  font-family: 'Fira Sans Bold';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/fira-sans-v10-latin-ext_latin-700.eot');
  src: local('Fira Sans Bold'), local('FiraSans-Bold'),
  url('./fonts/fira-sans-v10-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'),
  url('./fonts/fira-sans-v10-latin-ext_latin-700.woff2') format('woff2'),
  url('./fonts/fira-sans-v10-latin-ext_latin-700.woff') format('woff'),
  url('./fonts/fira-sans-v10-latin-ext_latin-700.ttf') format('truetype'),
  url('./fonts/fira-sans-v10-latin-ext_latin-700.svg#FiraSans') format('svg');
}


@font-face {
  font-family: "Icons";
  font-display: swap;
  src: url("./fonts/icons/icons.eot");
  src: url("./fonts/icons/icons.eot?#iefix") format("embedded-opentype"),
  url("./fonts/icons/icons.woff") format("woff"),
  url("./fonts/icons/icons.ttf") format("truetype"),
  url("./fonts/icons/icons.svg#icons") format("svg");
  font-weight: normal;
  font-style: normal;
}


[class^="fi-"]:before,
[class*=" fi-"]:before,
[class^="fi-"]:after,
[class*=" fi-"]:after {
  font-family: "Icons";
  font-size: 20px;
  font-style: normal;
}
.fi-sitemap:before {
  content: "\f103";
  font-size: 41px;
}
.fi-design:before {
  content: "\f10b";
  font-size: 50px;
}
.fi-website:before {
  content: "\f109";
  font-size: 53px;
}
.fi-apps:before {
  content: "\f102";
  font-size: 45px;
}
.fi-seo:before {
  content: "\f108";
  font-size: 41px;
}
.fi-cms:before {
  content: "\f106";
  font-size: 42px;
  margin-left: 5px;
}
.fi-domains:before {
  content: "\f10a";
  font-size: 46px;
}
.fi-text-writing:before {
  content: "\f107";
  font-size: 44px;
}
.fi-facebook:before {
  content: "\f105";
  font-size: 50px;
}
.fi-adwords:before {
  content: "\f100";
  font-size: 42px;
}
.fi-envelope:before {
  content: "\f104";
  font-size: 20px;
  position: relative;
  top: 2px;
}
.fi-phone:before {
  content: "\f108";
  font-size: 14px;
}

.fi-domene {
  display: block;
  margin-top: 17px;

  &:before {
    content: url("../../img/domene.svg");
  }
}

$font-primary: "Fira Sans", sans-serif;
$font-primary-bold: "Fira Sans Bold", sans-serif;