//
// SERVICES
// - - - - - - - - - - - - - - - - -


.dspot-services-list {
  .dspot-service {
    margin-bottom: 35px;

    img {
      margin-bottom: 25px;
    }

    .title {
      font-style: normal;
      font-family: $font-primary-bold;
      font-size: 20px;
      line-height: 26px;
      color: $grey-dark;
    }
  }
}

.inner-content {
  justify-content: center;
}

#counter {
  #count {
    &:first-child,
    &:nth-of-type(3) {
      .number:after {
        content: "+";
      }
    }
  }
  .number {
    text-align: center;
    font-family: $font-primary-bold;
    font-size: 65px;
    line-height: 70px;
    margin-bottom: 15px;
    color: $primary;
  }

  .title {
    text-align: center;
    color: $white;
    font-size: 16px;
    font-family: $font-primary-bold;
  }
}

#parallax {
  .parallax {
    .inner-content {
      &.our-story {
        .our-story-content {
          padding: 40px 20px;
          background: rgba(255, 255, 255, 0.8);

          @include media-breakpoint-up(md) {
            padding: 60px 90px;
          }

          h3 {
            color: $grey-dark;
          }

          p {
            color: $grey-dark;
          }
        }
      }
    }
  }
}
