//
// MATERIAL ICONS
// - - - - - - - - - - - - - - -

@import '../../../node_modules/material-icons/iconfont/variables';
@import '../../../node_modules/material-icons/iconfont/mixins';

$material-icons-font-file: "./fonts/iconfont/MaterialIcons-Regular";

@font-face {
  font-family: $material-icons-font-family;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('#{$material-icons-font-file}.eot'); /* For IE6-8 */
  src: local($material-icons-font-family), local($material-icons-font-name),
    url('#{$material-icons-font-file}.woff2') format('woff2'),
    url('#{$material-icons-font-file}.woff') format('woff'),
    url('#{$material-icons-font-file}.ttf') format('truetype');
}

.material-icons {
  @include material-icons();
}
