.pagination {
    .pagination-page {
      width: 48px;
      height: 48px;
      color: $black;
      background: none;
      text-align: center;
      margin: 0 8px;
      font-size: 16px;
      line-height: 45px;
      border: 1px solid transparent;
      font-family: $font-primary-bold;
      transition: all 0.25s;
      border-radius: 50px;
      display: inline-block;
      text-decoration: none;
  
      &:hover,
      &.pagination-active {
        color: $white;
        background: $primary;
        border: 1px solid $primary;
        text-decoration: none;
      }
    }
  
    .pagination-separator {
      margin: 0 7px;
    }
  }
  