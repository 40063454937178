#blog-list {
  padding-top: 80px;
  padding-bottom: 40px;

  .blog-posts {
    .blog-post {
      position: relative;
      cursor: pointer;
      margin-bottom: 30px;
      .link {
        @extend .absolute-link;
      }
      .image {
        height: 250px;
        margin: 0 0 15px;
        @extend .image-zoom-hover;
      }
      .content {
        .read-more {
          font-size: 15px;
          color: $primary;
          text-transform: uppercase;
          font-family: $font-primary-bold;
          transition: all 0.3s ease-in-out;
          text-decoration: none;
        }
        h2 {
          color: $grey-dark;
          font-family: $font-primary-bold;
          font-size: 20px;
          line-height: 24px;
          margin: 0 0 10px;
        }
      }
      &:first-child {
        .image {
          @include media-breakpoint-up(md) {
            height: 450px;
          }
        }
      }
      &:hover {
        .read-more {
          color: $secondary;
        }

        .image {
          div {
            opacity: 0.8;
            transform: translate3d(0, 0, 0) scale(0.95);
          }
        }
      }
    }
  }

  .blog-sidebar {
    @include media-breakpoint-up(md) {
      padding-left: 60px;
    }

    .blog-popular {
      .blog-popular-title {
        font-family: $font-primary-bold;
        color: $grey-dark;
        font-size: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid $primary;
      }
      .element {
        position: relative;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $grey-light;

        .link {
          color: $grey-dark;
          @extend .absolute-link;
        }
        .image {
          margin-right: 20px;
        }
        h3 {
          font-family: $font-primary-bold;
          font-size: 15px;
          line-height: 20px;
          color: $grey-dark;
          transition: all 0.3s ease-in-out;
        }

        &:hover {
          h3 {
            color: $secondary;
          }
        }
      }
    }
  }
}

#single-article {
  margin-top: 80px;
  margin-bottom: 40px;

  .article-data {
    h1 {
      font-size: 36px;
      line-height: 45px;
      font-family: $font-primary-bold;
      color: $grey-dark;

      &:after {
        @extend .heading-underline;
      }
    }

    .date,
    .author {
      font-style: italic;
      font-size: 14px;
      color: $grey-text;
      margin-bottom: 10px;
    }
  }

  .article-img {
    margin: 10px 0 30px 0;
  }

  .content-edit {
    a {
      &.cta-btn {
        color: white;
        text-decoration: none;
      }
    }

    h2,h3,h4 {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    img {
      max-width: 100%;
      height: auto;
      border: 2px solid #e1e1e1;
      padding: 15px;
      margin: 15px 0;
    }

    p {
      color: $grey-text;
    }
  }

  .article-share {
    padding: 40px 0;
    border-bottom: 1px solid $grey-background-light;

    .share {
      margin-right: 10px;
    }

    .icon {
      &.facebook {
        &:before {
          @extend .icon-facebook;
          top: 2px;
          width: 23px;
          height: 23px;
        }
      }
    }
  }

  .article-footer {
    padding-top: 40px;
    padding-bottom: 40px;

    .read-more-title {
      font-family: $font-primary;
      color: $grey-text;
      font-family: $font-primary-bold;
      font-size: 20px;
      margin-bottom: 20px;
    }

    .article-related {
      .element {
        cursor: pointer;
        .image {
          height: 250px;
          margin: 0 0 15px;
          @extend .image-zoom-hover;
        }
        .link {
          @extend .absolute-link;
        }
        .title {
          h3 {
            margin-top: 20px;
            font-size: 20px;
            line-height: 30px;
            color: $grey-dark;
          }
        }
        .read-more {
          font-size: 15px;
          color: $primary;
          text-transform: uppercase;
          font-family: $font-primary-bold;
          transition: all 0.3s ease-in-out;
        }

        &:hover {
          .read-more {
            color: $secondary;
          }

          .image {
            div {
              opacity: 0.8;
              transform: translate3d(0, 0, 0) scale(0.95);
            }
          }
        }
      }
    }
    .back {
      display: inline-block;
      text-decoration: underline;
      color: $grey-dark;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $primary;
        text-decoration: none;
      }
    }
  }

  table {
    tr {
      th,
      td {
        border: 0;
      }
    }
  }

  blockquote {
    position: relative;
    font-size: 18px;
    line-height: 28px;
    color: #959595;
    padding: 15px 25px 25px;
    border-bottom: 1px solid $primary;
    margin: 0;

    @include media-breakpoint-up(md) {
      font-size: 22px;
      line-height: 32px;
    }

    &:before {
      @extend .icon-quote;
    }
  }
}
