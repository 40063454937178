#visual-inner {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:before{
    content: '';
    background: rgba(0,0,0,.2);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }

  #page-title {
    h1 {
      color: $white;
      font-size: 40px;
      line-height: 46px;
      font-family: $font-primary-bold;

      @include media-breakpoint-up(md) {
        font-size: 50px;
        line-height: 56px;
      }
    }
  }
}
