#block-3part-inner {
  width: 100%;

  .title {
    h2 {
      margin-bottom: 15px;
      flex: 1;
      text-align: center;
      color: $white;
    }
  }

  #parts {
    .part {
      text-align: center;

      .icon {
        margin: 0px auto 20px;

        img {
          width: 110px;
          height: 110px;
        }
      }

      .title {
        margin: 0;

        h3 {
          color: $primary;
          font-size: 24px;
          margin-bottom: 10px;
        }
      }

      .description {
        p {
          color: $white;
          margin: 0;
        }
      }
    }
  }
}

#block-how-inner {
  .title {
    justify-content: center;

    h2 {
      margin-bottom: 45px;
    }
  }

  #parts {
    gap: 30px 0;
    margin-top: 30px;
    margin-bottom: 30px;

    .part-inner {
      height: 100%;
      background-color: $white;
      padding: 30px;
      transition: all 0.3s ease-in-out;

      &:hover {
        box-shadow: 0 2px 6px rgba(0, 1, 1, 0.2);
      }

      .content {
        text-align: center;

        a {
          color: $black;
          text-decoration: underline;

          &:hover {
            color: $primary;
            text-decoration: none;
          }
        }
      }
    }
  }
}

#paketi {
  gap: 30px 0;
  margin-top: 30px;
  margin-bottom: 30px;

  .paket {
    @include media-breakpoint-up(md) {
      padding: 30px;
    }

    .icon {
      margin-bottom: 40px;
      text-align: center;

      img {
        width: 110px;
        height: 110px;
      }
    }
  }
}

.three-col{
  h3{
    margin-bottom: 10px;
  }
}