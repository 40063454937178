#single-reference {
  .reference-visual {
    position: relative;
    height: 30vh;

    &:before {
      content: "";
      background: rgba(0, 0, 0, 0.4);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      z-index: 5;
    }
  }

  .reference-wrapper {
    margin-bottom: 50px;

    .reference-content {
      .image {
        position: relative;
        z-index: 99;
        padding: 10px;

        @include media-breakpoint-up(md) {
          margin-top: -290px;
          padding: 0;
        }

      }

      .customer {
        font-style: italic;
        font-size: 16px;
        color: $grey-text;
        font-family: $font-primary;
        font-weight: 400;
        text-transform: uppercase;
        margin: 0 0 30px;
      }

      h1 {
        font-size: 36px;
        line-height: 45px;
        font-family: $font-primary-bold;
        color: $grey-dark;

        &:after {
          @extend .heading-underline;
        }
      }
      .content-edit {
        a {
          &.cta-btn {
            color: white;
            text-decoration: none;
          }
        }
        img {
          max-width: 100%;
          height: auto;
          margin: 15px 0;
        }
        p {
          color: $grey-text;

        }
      }
      .reference-button {
        margin-top: 20px;
        padding-top: 30px;
        border-top: 1px dashed $grey-light;
        

        @include media-breakpoint-up(md) {
          margin-top: 50px;
          display: flex;
          justify-content: space-between;
        }

        .cta-btn {
          margin-top: 20px;
          display: block;
          text-transform: none;
        }
      }
    }
  }

  .related-references {
    background: $grey-background-light;
    padding-top: 50px;
    padding-bottom: 50px;

    .reference-related {

      .element {
        cursor: pointer;

        .link {
          @extend .absolute-link;
        }

        .image {
          height: 250px;
          @extend .image-zoom-hover;
        }

        .content {
          margin-top: 20px;
          text-align: left;

          @include media-breakpoint-up(md) {
            margin-top: 0;
            text-align: right;
          }

          h3 {
            font-family: $font-primary-bold;
            font-size: 24px;
            color: #7d7d7d;
            margin-bottom: 40px;

            @include media-breakpoint-up(md) {
              margin-bottom: 80px;
            }
          }

          .next-project {
            font-size: 26px;
            line-height: 36px;
            color: $grey-background-two;
            font-family: $font-primary-bold;
            margin-bottom: 15px;

            span {
              color: $primary;
            }

            @include media-breakpoint-up(md) {
              font-size: 36px;
              line-height: 46px;
            }
          }
          .read-more {
            color: $grey-background-two;
            font-size: 26px;
            transition: all 0.3s ease-in-out;
          }
        }

        &:hover {
          .read-more {
            color: $primary;
          }
          .image {
            div {
              opacity: 0.8;
              transform: translate3d(0, 0, 0) scale(0.95);
            }
          }
        }
      }
    }
  }
}

#references {
  background: $grey-background-light;
  padding-top: 50px;
  padding-bottom: 40px;

  h1 {
    font-size: 36px;
    line-height: 45px;
    font-family: $font-primary-bold;
    margin: 0 0 20px;
    color: $grey-dark;
    text-align: center;
  }

  .references-list {
    margin-top: 46px;

    .element {
      cursor: pointer;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin-bottom: 40px;
      transition: all 0.3s ease-in-out;

      @include media-breakpoint-up(lg) {
        padding: 0 60px;
      }

      .image {
        position: relative;
        overflow: hidden;
        height: 350px;
        display: block;
        transition: opacity 0.6s, transform 0.5s;
      }

      .overlay {
        transition: all 0.3s ease-in-out;
      }

      .content {
        width: 100%;
        text-align: center;
        margin-top: 15px;

        @include media-breakpoint-up(lg) {
          position: absolute;
          top: 40%;
          left: 0;
          margin-top: 0;
        }

        .content-edit {
          p {
            color: $primary;
            font-size: 20px;
            line-height: 34px;
            font-family: $font-primary-bold;

            @include media-breakpoint-up(lg) {
              color: $white;
              transition: opacity 0.35s, transform 0.35s;
              transform: translate(-100%, 0);
            }
          }
        }

        .web-url,
        .category-title {
          font-family: $font-primary-bold;
          color: $primary;
          font-size: 20px;
          line-height: 34px;
          margin: 0;
          padding: 0;

          @include media-breakpoint-up(lg) {
            color: $white;
            transition: opacity 0.35s, transform 0.35s;
            transform: translate(-100%, 0);
          }
        }
        .web-url {
          transition-delay: 0.2s;
        }

        .category-title {
          transition-delay: 0.1s;
          font-family: $font-primary-bold;
        }
      }

      .link {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 600;
      }

      &:hover {
        .image {
          @include media-breakpoint-up(lg) {
            transform: scale(1.1);
            overflow: hidden;
          }
        }
        .overlay {
          @include media-breakpoint-up(lg) {
            background: rgba(245, 127, 41, 0.9);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        .web-url,
        .category-title {
          @include media-breakpoint-up(lg) {
            transform: translate(0, 0);
          }
        }
        .content-edit {
          p {
            @include media-breakpoint-up(lg) {
              transform: translate(0, 0);
            }
          }
        }
      }
    }
  }
}
