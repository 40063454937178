.nav {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  text-decoration: none;
  padding: 10px;
}

.nav-pills {
  display: none;

  @media screen and (min-width: 992px) {
    display: flex !important;
  }
}

.tab-content {
  padding-bottom: 96px;
  
  .tab-pane {
    display: block;
    opacity: 1;
  }
}

@media screen and (min-width: 992px) {
  .fade {
    @include transition($transition-fade);

    &:not(.show) {
      opacity: 0;
    }
  }

  .tab-content {
    .tab-pane {
      display: none;
    }
    .active {
      display: block;
    }
  }
}

.help-tabs {
  .tabs-content {
    .nav-pills {
      padding-top: 50px;
      padding-bottom: 50px;
      gap: 12px;

      .nav-link {
        font-family: $font-primary;
        font-size: 20px;
        color: $white;
        border: none;
        border-radius: 3px;
        background: $secondary;
        cursor: pointer;

        &:hover,
        &.active {
          background: $primary;
        }
      }
    }

    .tab-content {
      @media screen and (max-width: 991px) {
        padding-top: 48px;
        padding-bottom: 64px;
      }

      .tab-pane {
        .tab-header {
          a {
            text-decoration: none;
            padding-top: 24px;
            padding-bottom: 24px;
            color: $grey-dark;
            font-family: $font-primary-bold;
            border-top: 1px solid #868686;

            .icon-minus {
              display: none;
            }

            h3 {
              font-family: $font-primary;
              font-size: 20px;
              line-height: 30px;
              margin: 0;
              color: #868686;

              &:hover {
                color: $dark;
              }
            }

            &[aria-expanded="true"] {
              .icon-minus {
                display: block;
              }
              .icon-plus {
                display: none;
              }
              h3 {
                color: $dark;
                text-decoration: underline;
              }
            }
          }

          @media screen and (min-width: 992px) {
            display: none;
          }
        }

        &:first-of-type {
          .tab-header {
            a {
              border-top: none;
            }
          }
        }

        &:last-of-type {
          .tab-header {
            a {
              &[aria-expanded="false"] {
                border-bottom: 1px solid #868686;
              }
            }
          }
        }

        .tab-body {
          text-align: left;

          .content-edit {
            @media screen and (max-width: 991px) {
              padding-top: 32px;
              padding-bottom: 32px;
            }
          }

          .bg-gray {
            .content-edit {
              padding-top: 32px;
              padding-bottom: 32px;

              @media screen and (min-width: 992px) {
                padding-top: 78px;
                padding-bottom: 78px;
              }
            }
          }
        }
      }
    }
  }
}
