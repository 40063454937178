//
// CARRERS
// - - - - - - - - - - - - - - - - - - - -

#positions-preview {
  .positions-group {
    display: flex;
    flex-wrap: wrap;

    .position {
      border: 1px solid rgba(0,0,0,0.5);
      flex: 23%;
      margin-bottom: 20px;
      margin-left: 10px;
      margin-right: 10px;

      @media (max-width: 992px) {
        flex: 45%;
      }

      @media (max-width: 600px) {
        flex: 100%;

        .row {
          flex-direction: column;
        }
      }

      a {
        color: $black;
        font-family: $font-primary-bold;
      }

      .image {
        img {
          width: 100%;
        }
      }

      .card-block {
        display: flex;
        padding: 11px;
        color: $black;
        font-size: 18px;
        font-family: $font-primary-bold;
        line-height: 23px;

        i {
          vertical-align: middle;
          line-height: 40px;
          padding-right: 14px;
        }
      }

      .card-footer {
        background: none;
        text-align: right;
        border: none;
      }
    }
  }

  #more-positions {
    margin-top: 44px;
    margin-bottom: 64px;
    text-align: center;

    .btn-more {
   
      color: $blue;
      border: 2px solid $blue;

      &:hover {
        color: $white;
        background: $blue;
        text-decoration: none;
      }
    }
  }
}
