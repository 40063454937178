#cookie-consent {
  display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  max-width: 765px;
  width: 100%;
  z-index: 10000;
  color: #adadad;
  font-style: normal;
  font-size: 12px;
  padding: 24px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  .cookie-consent-header {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #d1d1d1;

    .title {
      display: flex;
      justify-content: space-between;
    }

    .main-cookie-title {
      margin-bottom: 12px;
    }

    button {
      width: 24px;
      height: 24px;
      background: none;
      border: none;
      cursor: pointer;
    }
  }

  .cookie-consent-options {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #d1d1d1;
    display: flex;
    justify-content: space-evenly;

    .input {
      display: flex;
      align-items: center;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-evenly;


    button {
      border: none;
      padding: 12px 26px;
      @extend .cta-btn;
    }
  }
}
